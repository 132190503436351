const onFirst = (elem, events, name, callback) => {
  window.ran = window.ran || {}
  window.ran[name] = false

  events.forEach((event) => {
    elem.addEventListener(event, () => {
      if (window.ran[name]) {
        return
      }

      callback()

      window.ran[name] = true
    })
  })
}

export default onFirst
